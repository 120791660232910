// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/order/provider.ts"
);
import.meta.hot.lastModified = "1731350006751.4983";
}
// REMIX HMR END

import { QueryOptions, sdk } from '~storefront/graphqlWrapper'
import {
  CreateAddressInput,
  CreateCustomerInput,
  OrderLineCustomFieldsInput,
} from '~storefront/generated/graphql'

export const transitionOrderToState = (
  state: string,
  options?: QueryOptions,
) => {
  return sdk
    .transitionOrderToState({ state }, options)
    .then(({ transitionOrderToState }) => transitionOrderToState)
}

export const assignClassGroupToOrderLine = (
  orderLineId: string,
  classGroupId: string,
  options?: QueryOptions,
) => {
  return sdk.rsv_assignClassGroupToOrderLine(
    { orderLineId, classGroupId },
    options,
  )
}

export function getActiveOrder(options: QueryOptions) {
  return sdk
    .activeOrder(undefined, options)
    .then(({ activeOrder }) => activeOrder)
}

export function getOrderByCode(code: string, options: QueryOptions) {
  return sdk
    .orderByCode({ code }, options)
    .then(({ orderByCode }) => orderByCode)
}

export function addItemToOrder(
  productVariantId: string,
  quantity: number,
  customFields: OrderLineCustomFieldsInput,
  options: QueryOptions,
) {
  console.log(
    'Adding Item to Order - custom fields' + JSON.stringify(customFields),
  )
  return sdk.addItemToOrder(
    {
      productVariantId,
      quantity,
      customFields,
    },
    options,
  )
}

export function removeOrderLine(lineId: string, options: QueryOptions) {
  return sdk.removeOrderLine({ orderLineId: lineId }, options)
}

export function applyCouponCode(couponCode: string, options: QueryOptions) {
  return sdk.applyCouponCode({ couponCode: couponCode }, options)
}

export function removeCouponCode(couponCode: string, options: QueryOptions) {
  return sdk.removeCouponCode({ couponCode: couponCode }, options)
}

export function adjustOrderLine(
  lineId: string,
  quantity: number,
  customFields?: OrderLineCustomFieldsInput,
  options?: QueryOptions,
) {
  return sdk.adjustOrderLine(
    { orderLineId: lineId, quantity, customFields },
    options,
  )
}

export function setCustomerForOrder(
  input: CreateCustomerInput,
  options: QueryOptions,
) {
  return sdk.setCustomerForOrder({ input }, options)
}

export function setOrderBillingAddress(
  input: CreateAddressInput,
  options: QueryOptions,
) {
  return sdk.setOrderBillingAddress({ input }, options)
}

export function setOrderShippingAddress(
  input: CreateAddressInput,
  options: QueryOptions,
) {
  return sdk.setOrderShippingAddress({ input }, options)
}

export function setOrderShippingMethod(
  shippingMethodId: string,
  options: QueryOptions,
) {
  return sdk.setOrderShippingMethod({ shippingMethodId }, options)
}
